<template lang="pug">
main-layout
  v-dialog(v-model='isDialogActive' hide-overlay transition="dialog-bottom-transition" scrollable)
    v-card(tile)
      v-toolbar(card dark color='#009dd1') {{ dialogTitle }}
      template(v-if="dialogType === 'create-register' || dialogType === 'update-register'")
        v-card-text
          v-text-field(label="Titel" v-model="register.title")
          v-text-field(label="Sitz" v-model="register.address")
          input-date(label="Datum der Errichtung" v-model="register.creationDate")
          v-card-actions(v-if="dialogType === 'create-register'")
            v-btn(@click="confirmRegisterCreate") Speichern
            v-btn(@click.native="closeDialog") Abbrechen
          v-card-actions(v-else)
            v-btn(@click="confirmRegisterUpdate") Speichern
            v-btn(@click.native="closeDialog") Abbrechen

  v-data-table(
    must-sort 
    no-data-text="Keine Daten vorhanden" 
    no-results-text="keine passenden Daten"
    hide-actions
    item-key="id"
    :headers="registerTableHeaders"
    :items="allRegisters"
  )
    template(slot="items" slot-scope="props")
      tr
        td {{ props.item.id }}
        td {{ props.item.title }}
        td {{ props.item.creationDate }}
        td
          v-btn(icon @click="props.expanded = !props.expanded"): v-icon {{ !props.expanded ? 'expand_more' : 'expand_less' }}
        td
          v-btn(icon @click="registerUpdate(props.item)"): v-icon edit
        td
          v-btn(icon @click="registerDestroy(props.item.id)"): v-icon delete
    div(slot="expand" slot-scope="props" style="margin: 3%; width: 90%")
      register-documents-table(:registerId="props.item.id")
      register-entries-table(:registerId="props.item.id")

  v-btn(@click="registerCreate") Neues Register Anlegen
</template>

<script>
import gql from "graphql-tag";
import GraphqlErrorObject from "./../graphql-error-object.js";
import MainLayout from "../layouts/Main.vue";
import RegisterEntriesTable from "./RegisterEntriesTable.vue";
import RegisterDocumentsTable from "./RegisterDocumentsTable.vue";
import InputDate from "../components/input-date.vue";

export default {
  components: {
    MainLayout,
    RegisterEntriesTable,
    RegisterDocumentsTable,
    InputDate,
  },
  mixins: [GraphqlErrorObject],
  data() {
    return {
      isDialogActive: false,
      dialogType: "",
      register: {
        id: null,
        address: "",
        title: "",
        creationDate: "",
      },
      registerTableHeaders: [
        { text: "ID", value: "id" },
        { text: "Registername", value: "title" },
        { text: "Datum der Errichtung", value: "creationDate" },
        { text: "Registereinträge Anzeigen" },
        { text: "Register Bearbeiten" },
        { text: "Register Löschen" },
      ],
    };
  },
  apollo: {
    allRegisters: gql`
      query RegisterEntries {
        allRegisters {
          id
          address
          title
          creationDate
        }
      }
    `,
  },
  computed: {
    dialogTitle() {
      if (this.dialogType === "create-register") {
        return "Neues Register";
      } else if (this.dialogType === "update-register") {
        return "Register bearbeiten";
      }
      return "Neuer Registereintrag";
    },
  },
  methods: {
    registerCreate() {
      this.gqlClearAllErrors();
      this.dialogType = "create-register";
      this.isDialogActive = true;
      this.register = {
        title: "",
        creationDate: "",
        address: "",
      };
    },
    async confirmRegisterCreate() {
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation RegisterCreate(
              $title: String!
              $creationDate: String!
              $address: String
            ) {
              registerCreate(
                title: $title
                creationDate: $creationDate
                address: $address
              ) {
                id
              }
            }
          `,
          variables: {
            title: this.register.title,
            creationDate: this.register.creationDate,
            address: this.register.address,
          },
        });
        this.isDialogActive = false;
        await this.$apollo.queries.allRegisters.refetch();
      } catch (error) {
        this.gqlAddErrors(error);
      }
    },

    registerUpdate(register) {
      this.gqlClearAllErrors();
      this.dialogType = "update-register";
      this.isDialogActive = true;
      this.register = {
        id: register.id,
        title: register.title,
        creationDate: register.creationDate,
        address: register.address,
      };
    },
    async confirmRegisterUpdate() {
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation RegisterUpdate(
              $id: ID!
              $title: String!
              $creationDate: String!
              $address: String
            ) {
              registerUpdate(
                id: $id
                title: $title
                creationDate: $creationDate
                address: $address
              ) {
                id
              }
            }
          `,
          variables: {
            id: this.register.id,
            title: this.register.title,
            creationDate: this.register.creationDate,
            address: this.register.address,
          },
        });
        this.isDialogActive = false;
        await this.$apollo.queries.allRegisters.refetch();
      } catch (error) {
        this.gqlAddErrors(error);
      }
    },

    async registerDestroy(id) {
      if (!confirm("Register wirklich löschen?")) {
        return;
      }
      this.gqlClearAllErrors();
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation RegisterDestroy($id: ID!) {
              registerDestroy(id: $id) {
                id
              }
            }
          `,
          variables: {
            id: id,
          },
        });
        await this.$apollo.queries.allRegisters.refetch();
      } catch (error) {
        this.gqlAddErrors(error);
      }
    },
    closeDialog() {
      this.isDialogActive = false;
      this.gqlClearAllErrors();
    },
  },
};
</script>
<style lang="stylus" scoped>
.error-msg
  padding 20em 0
  text-align center
</style>
