<template lang="pug">
div
  v-btn(@click="registerDocumentCreate") Register Dokument Hinzufügen
  v-spacer
  v-dialog(v-model='isDialogActive' hide-overlay transition="dialog-bottom-transition" scrollable)
    v-card(tile)
      v-toolbar(card dark color='#009dd1') Register Dokument
      v-card-text
        file-input(label="Dokument hochladen" v-model="registerDocument.documentName" base64 @base64="registerDocument.documentUrl = $event")
        v-btn(@click="confirmRegisterDocumentCreate") Speichern
        v-btn(@click.native="closeDialog") Abbrechen
  v-data-table(
    no-data-text="Keine Daten vorhanden" 
    no-results-text="keine passenden Daten"
    :headers="registerDocumentHeaders"
    :items="registerDocuments"
    hide-actions
  )
    template(slot="items" slot-scope="props")
      tr
        td
          a(:href="props.item.documentUrl") {{ props.item.documentName }}
        td
          v-btn(icon @click="registerDocumentDestroy(props.item.id)"): v-icon delete
</template>
<script>
import gql from "graphql-tag";
import GraphqlErrorObject from "./../graphql-error-object.js";
import FileInput from "../components/file-input.vue";
export default {
  name: "RegisterDocumentsTable",
  components: {
    FileInput,
  },
  mixins: [GraphqlErrorObject],
  props: {
    registerId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isDialogActive: false,
      register: this.registerId,
      dialogType: "",
      registerDocumentHeaders: [
        { text: "Dokument" },
        { text: "Dokument löschen" },
      ],
      registerDocument: {
        id: null,
        documentUrl: "",
        documentName: "",
        registerId: this.registerId,
      },
    };
  },
  apollo: {
    register: {
      query: gql`
        query Register($registerId: ID!) {
          register(id: $registerId) {
            id
            registerDocuments {
              id
              documentName
              documentUrl
            }
          }
        }
      `,
      variables() {
        return {
          registerId: this.registerId,
        };
      },
    },
  },
  computed: {
    registerDocuments() {
      return this.register.registerDocuments || [];
    },
  },
  methods: {
    registerDocumentCreate() {
      this.gqlClearAllErrors();
      this.isDialogActive = true;
      this.registerRegisterDocument = {
        id: null,
        documentUrl: "",
        documentName: "",
      };
    },
    async confirmRegisterDocumentCreate() {
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation RegisterDocumentCreate(
              $documentUrl: String!
              $documentName: String!
              $registerId: ID!
            ) {
              registerDocumentCreate(
                documentUrl: $documentUrl
                documentName: $documentName
                registerId: $registerId
              ) {
                id
              }
            }
          `,
          variables: {
            documentUrl: this.registerDocument.documentUrl,
            documentName: this.registerDocument.documentName,
            registerId: this.registerId,
          },
        });
        await this.$apollo.queries.register.refetch();
        this.isDialogActive = false;
      } catch (error) {
        this.gqlAddErrors(error);
      }
    },
    async registerDocumentDestroy(id) {
      if (!confirm("Registereintrag wirklich löschen?")) {
        return;
      }
      this.gqlClearAllErrors();
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation registerDocumentDestroy($id: ID!) {
              registerDocumentDestroy(id: $id)
            }
          `,
          variables: {
            id,
          },
        });
        await this.$apollo.queries.register.refetch();
      } catch (error) {
        this.gqlAddErrors(error);
      }
    },
    closeDialog() {
      this.isDialogActive = false;
      this.gqlClearAllErrors();
    },
  },
};
</script>
