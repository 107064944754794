<template lang="pug">
main-layout
  v-card
    v-card-title
      h1 Willkommen
</template>
<script>
import MainLayout from "./layouts/Main.vue";
export default {
  components: {
    MainLayout,
  },
};
</script>
