<template lang="pug">
v-container(grid-list-md)
  v-card
    v-card-text
      h1.headeine Anmelden
      v-layout(row wrap)
        v-flex(xs12 sm4 md5): v-text-field(label="Benutzername" id="user-name" v-model="user.username" :error-messages="gqlErrorsFor('userSignIn', 'username')")
        v-flex(xs12 sm4 md5): v-text-field(label="Passwort" id="user-password" v-model="user.password" type="password" :error-messages="gqlErrorsFor('userSignIn', 'password')")
        v-flex(xs12 sm4 md2): v-btn(color="primary" block @click="anmelden" :loading="loading > 0") Anmelden
        v-flex(xs12): v-alert(:value="gqlErrorsFor('userSignIn', 'error').length" type="error") {{gqlErrorsFor('userSignIn', 'error').join(', ')}}
        v-flex.pt-5(xs12)

</template>

<script>
import gql from "graphql-tag";
import GraphqlErrorObject from "../graphql-error-object.js";

export default {
  mixins: [GraphqlErrorObject],
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      loading: 0,
    };
  },
  methods: {
    anmelden() {
      this.gqlClearAllErrors();
      this.loading += 1;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation UserLoginVueSignIn($username: String, $password: String) {
              userSignIn(username: $username, password: $password) {
                token
                user {
                  id
                  firstName
                  lastName
                  username
                  email
                }
              }
            }
          `,
          variables: this.user,
        })
        .then((response) => {
          this.user.password = "";
          this.$store.commit("storeAuth", response.data.userSignIn);
          this.$router.push("/");
          this.loading -= 1;
        })
        .catch((error) => {
          this.loading -= 1;
          this.gqlAddErrors(error);
        });
    },
  },
};
</script>
<style lang="stylus" scoped>
.error-msg
  padding 2em 0
  text-align center
</style>
