<template lang="pug">
component(:is="layout")
  div(style="background-color: white")
    v-container(grid-list-lg justify-end)
      v-card.mb-5
        template(v-for="register in allRegisters")
          v-btn(:key="register.id" depressed outline :color="`${register.id === activeRegister.id ? 'primary' : 'grey'}`" style="text-transform: none;"  @click="selectRegister(register.id)") {{ register.title }}


        v-card.mb-3
          v-card-title.headline {{ activeRegister.title || 'Wähle ein Register aus'}}
          div(v-if="activeRegister.id")
            v-card-title Aktueller Ausdruck
            ol.mt-3(style="list-style-type: none ")
              li.mt-4
                strong 1
                ol(style="list-style-type: none ")
                  li
                    strong a) Anzahl der bisherigen Eintragungen 
                    br
                    span {{ activeRegister.registerEntries.length }}
                    br
                    br
                strong 2
                ol(style="list-style-type: none ")
                  li
                    strong a) Verbandsbezeichnung
                    br
                    span {{ activeRegister.title }}
                    br
                    br
                  li
                    strong b) Sitz
                    br
                    span {{ activeRegister.address }}
                    br
                    br
                strong 3
                ol(style="list-style-type: none ")
                  li
                    strong a) Allgemeine Vertretungsregelung
                    br
                    span {{ lastRegisterEntry.regulations }}
                    br
                    br
                  li
                    strong b) Vorstand, Namen der weiteren Vertretungsberechtigten
                    br
                    .span-group
                      span(v-if="lastRegisterEntry.chairman") {{ 'Vorstandsvorsitzende*r: ' + lastRegisterEntry.chairman }}
                      span(v-if="lastRegisterEntry.board") {{ 'Vorstand: ' + lastRegisterEntry.board }}
                      span(v-if="lastRegisterEntry.deputyChairman") {{ 'stellvertrentende*r Vorsitzende*r: ' + lastRegisterEntry.deputyChairman }}
                      span(v-if="lastRegisterEntry.managementDirector") {{ 'Geschäftsführung: ' + lastRegisterEntry.managementDirector }}
                      span(v-if="lastRegisterEntry.socialDepartmentDirector") {{ 'Abteilung Soziales: ' + lastRegisterEntry.socialDepartmentDirector }}
                      span(v-if="lastRegisterEntry.centralServicesDirector") {{ 'Abteilung Zentrale Dienste: ' + lastRegisterEntry.centralServicesDirector }}
                      span(v-if="lastRegisterEntry.educationDepartmentDirector") {{ 'Abteilung Bildung/Hum. Lebenskunde: ' + lastRegisterEntry.educationDepartmentDirector }}
                      span(v-if="lastRegisterEntry.dayCareDepartmentDirector") {{ 'Abteilung Hum. Kindertagesstätten: ' + lastRegisterEntry.dayCareDepartmentDirector }}
                      span(v-if="lastRegisterEntry.youthDepartmentDirector") {{ 'Abteilung Jugend: ' + lastRegisterEntry.youthDepartmentDirector }}
                      span(v-if="lastRegisterEntry.hospiceDepartmentDirector") {{ 'Abteilung Hum. Hospize: ' + lastRegisterEntry.hospiceDepartmentDirector }}
                    br
                    br
                strong 4
                ol(style="list-style-type: none ")
                  li
                    strong a) Datum der Errichtung 
                    br
                    span {{ activeRegister.creationDate ? activeRegister.creationDate.split('T')[0].split('-').reverse().join('.') : '' }}
                    br
                    br
                  li
                    strong b) Angaben zur Rechtsform 
                    br
                    span {{ lastRegisterEntry.legalForm }}
                    br
                    br
                strong 5
                ol(style="list-style-type: none ")
                  li
                    strong a) Name der eintragenden Person
                    br
                    span {{ lastRegisterEntry.createdBy.firstName }} {{ lastRegisterEntry.createdBy.lastName }}
                    br
                    br
                  li
                    strong b) sonstige Bemerkungen
                    br
                    span {{ lastRegisterEntry.comment }}
                    br
                    br
                strong 6
                ol(style="list-style-type: none ")
                  li
                    strong a) Tag der letzten Eintragung
                    br
                    span {{ lastRegisterEntry.createdAt.split('T')[0].split('-').reverse().join('.') }}
                    br
                    br

            br
            v-btn(:href="`/pdf/recent/${activeRegister.id}`" :target="'_blank'" icon large)
              v-icon(color="primary") print


      v-card(v-if="activeRegister.id" class="mt-5")
        v-card-title Eintragungen chronologisch
        table(class="register-table")
          tr
            th(class="register-th") Nr. der Eintragung
            th(class="register-th")
              .table-entry a) Verbandsbezeichnung 
              .table-entry b) Sitz
            th(class="register-th")
              .table-entry a) Allgemeine Vertretungsregelungen
              .table-entry b) Vorstand und weitere Vertretungsberechtigte
            th(class="register-th")
              .table-entry a) Datum der Errichtung 
              .table-entry b) Angaben zur Rechtsform 
              .table-entry c) Datum der letzten Satzungsänderung
            th(class="register-th")
              .table-entry a) Datum der Eintragung 
              .table-entry b) Name der/des Eintragenden 
              .table-entry c) sonstige Bemerkungen
            tr
              td(class="register-td"): strong I
              td(class="register-td"): strong II
              td(class="register-td"): strong III
              td(class="register-td"): strong IV
              td(class="register-td"): strong V
            tr(v-for="entry, i in activeRegister.registerEntries" :key="entry.id")
              td(class="register-td")
                strong {{i + 1}}
              td(class="register-td")
                div(v-if="i == 0")
                  .table-entry a)
                  .table-entry {{ activeRegister.title }}
                br
                div(v-if="i == 0")
                  .table-entry b)
                  .table-entry {{ activeRegister.address }}
              td(class="register-td")
                div(v-if="entry.regulations")
                  .table-entry a)
                  div(class="table-entry" :style="entry.regulations !== lastRegisterEntry.regulations ? 'text-decoration: line-through' : ''") {{ entry.regulations }}
                  br
                div(v-if="entry.board || entry.chairman || entry.deputyChairman || entry.managementDirector || entry.socialDepartmentDirector || entry.centralServicesDirector || entry.educationDepartmentDirector || entry.youthDepartmentDirector || entry.hospiceDepartmentDirector || entry.dayCareDepartmentDirector" class="span-group")
                  .table-entry b)
                  span(v-if="entry.chairman")
                    span Vorstandsvorsitzende*r: 
                    span(:style="entry.chairman !== lastRegisterEntry.chairman ? 'text-decoration: line-through' : ''") {{ entry.chairman }}
                  span(v-if="entry.board")
                    span Vorstand: 
                    span(:style="entry.board !== lastRegisterEntry.board ? 'text-decoration: line-through' : ''") {{ entry.board }}
                  span(v-if="entry.deputyChairman")
                    span stellvertrentende*r Vorsitzende*r: 
                    span(:style="entry.deputyChairman !== lastRegisterEntry.deputyChairman ? 'text-decoration: line-through' : ''") {{ entry.deputyChairman }}
                  span(v-if="entry.managementDirector")
                    span Geschäftsführung: 
                    span(:style="entry.managementDirector !== lastRegisterEntry.managementDirector ? 'text-decoration: line-through' : ''") {{ entry.managementDirector }}
                  span(v-if="entry.socialDepartmentDirector")
                    span Abteilung Soziales: 
                    span(:style="entry.socialDepartmentDirector !== lastRegisterEntry.socialDepartmentDirector ? 'text-decoration: line-through' : ''") {{ entry.socialDepartmentDirector }}
                  span(v-if="entry.centralServicesDirector")
                    span Abteilung Zentrale Dienste: 
                    span(:style="entry.centralServicesDirector !== lastRegisterEntry.centralServicesDirector ? 'text-decoration: line-through' : ''") {{ entry.centralServicesDirector }}
                  span(v-if="entry.educationDepartmentDirector")
                    span Abteilung Bildung/Hum. Lebenskunde: 
                    span(:style="entry.educationDepartmentDirector !== lastRegisterEntry.educationDepartmentDirector ? 'text-decoration: line-through' : ''") {{ entry.educationDepartmentDirector }}
                  span(v-if="entry.educationDepartmentDirector")
                    span Abteilung Hum. Kindertagesstätten: 
                    span(:style="entry.dayCareDepartmentDirector !== lastRegisterEntry.dayCareDepartmentDirector ? 'text-decoration: line-through' : ''")
                  span(v-if="entry.youthDepartmentDirector")
                    span Abteilung Jugend: 
                    span(:style="entry.youthDepartmentDirector !== lastRegisterEntry.youthDepartmentDirector ? 'text-decoration: line-through' : ''") {{ entry.youthDepartmentDirector }}
                  span(v-if="entry.hospiceDepartmentDirector")
                    span Abteilung Hum. Hospize: 
                    span(:style="entry.hospiceDepartmentDirector !== lastRegisterEntry.hospiceDepartmentDirector ? 'text-decoration: line-through' : ''") {{ entry.hospiceDepartmentDirector }}
              td(class="register-td")
                div(v-if="i == 0")
                  .table-entry a)
                  .table-entry {{ activeRegister.creationDate ? activeRegister.creationDate.split('T')[0].split('-').reverse().join('.') : "" }}
                  br
                div(v-if="entry.legalForm")
                  .table-entry b)
                  .table-entry(:style="entry.legalForm !== lastRegisterEntry.legalForm ? 'text-decoration: line-through;' : ''") {{ entry.legalForm }}
                  br
                div(v-if="entry.lastStatuteChangeDate")
                  .table-entry c)
                  div(class="table-entry" :style="entry.lastStatuteChangeDate !== lastRegisterEntry.lastStatuteChangeDate ? 'text-decoration: line-through;' : ''")
                    |{{ entry.lastStatuteChangeDate ? entry.lastStatuteChangeDate.split('T')[0].split('-').reverse().join('.') : '' }}
              td(class="register-td")
                .table-entry a)
                .table-entry {{ entry.createdAt.split('T')[0].split('-').reverse().join('.') }}
                br
                .table-entry b)
                .table-entry {{ entry.createdBy.firstName }} {{ entry.createdBy.lastName }}
                br
                div(v-if="entry.comment")
                  .table-entry c)
                  .table-entry {{ entry.comment }}
            tr
              td(class="register-td" colspan="8")
                strong Dokumente: 
                .span-group
                  span(v-for="registerDocument in activeRegister.registerDocuments" :key="registerDocument.id")
                    a(:href="registerDocument.documentUrl") {{ registerDocument.documentName }}
        br
        v-btn(:href="`/pdf/chronological/${activeRegister.id}`" :target="'_blank'" icon large)
          v-icon(color="primary") print



</template>

<script>
import gql from "graphql-tag";
import MainLayout from "../layouts/Main.vue";
import IFrameLayout from "../layouts/IFrame.vue";
import { compareAsc } from "date-fns";

export default {
  components: {
    MainLayout,
    IFrameLayout,
  },
  data() {
    return {
      activeRegister: {},
      registerEntryHeaders: [
        { text: "Sitz", value: "address", sortable: false },
        {
          text: "Vorstand und weitere Vertretungsberechtigte",
          value: "chairman",
          sortable: false,
        },

        { text: "Rechtsform", value: "legalForm", sortable: false },
        { text: "Regelungen", value: "regulations", sortable: false },
        { text: "Kommentar", value: "comment", sortable: false },
        {
          text: "Eingetragen von",
          value: "createdBy.firstName",
          sortable: false,
        },
        { text: "Eingetragen am", value: "createdAt", sortable: false },
      ],
    };
  },
  apollo: {
    allRegisters: {
      query: gql`
        query AllRegisters {
          allRegisters {
            id
            title
          }
        }
      `,
    },
  },
  computed: {
    // returns last entry that was not empty
    lastRegisterEntry() {
      return {
        board: this.activeRegister?.registerEntries
          .filter((entry) => entry.board !== "" && entry.board !== null)
          .slice(-1)[0]?.board,
        regulations: this.activeRegister?.registerEntries
          .filter(
            (entry) => entry.regulations !== "" && entry.regulations !== null,
          )
          .slice(-1)[0]?.regulations,
        chairman: this.activeRegister?.registerEntries
          .filter((entry) => entry.chairman !== "" && entry.chairman !== null)
          .slice(-1)[0]?.chairman,
        deputyChairman: this.activeRegister?.registerEntries
          .filter(
            (entry) =>
              entry.deputyChairman !== "" && entry.deputyChairman !== null,
          )
          .slice(-1)[0]?.deputyChairman,
        managementDirector: this.activeRegister?.registerEntries
          .filter(
            (entry) =>
              entry.managementDirector !== "" &&
              entry.managementDirector !== null,
          )
          .slice(-1)[0]?.managementDirector,
        socialDepartmentDirector: this.activeRegister?.registerEntries
          .filter(
            (entry) =>
              entry.socialDepartmentDirector !== "" &&
              entry.socialDepartmentDirector !== null,
          )
          .slice(-1)[0]?.socialDepartmentDirector,
        centralServicesDirector: this.activeRegister?.registerEntries
          .filter(
            (entry) =>
              entry.centralServicesDirector !== "" &&
              entry.centralServicesDirector !== null,
          )
          .slice(-1)[0]?.centralServicesDirector,
        educationDepartmentDirector: this.activeRegister?.registerEntries
          .filter(
            (entry) =>
              entry.educationDepartmentDirector !== "" &&
              entry.educationDepartmentDirector !== null,
          )
          .slice(-1)[0]?.educationDepartmentDirector,
        youthDepartmentDirector: this.activeRegister?.registerEntries
          .filter(
            (entry) =>
              entry.youthDepartmentDirector !== "" &&
              entry.youthDepartmentDirector !== null,
          )
          .slice(-1)[0]?.youthDepartmentDirector,
        hospiceDepartmentDirector: this.activeRegister?.registerEntries
          .filter(
            (entry) =>
              entry.hospiceDepartmentDirector !== "" &&
              entry.hospiceDepartmentDirector !== null,
          )
          .slice(-1)[0]?.hospiceDepartmentDirector,
        dayCareDepartmentDirector: this.activeRegister?.registerEntries
          .filter(
            (entry) =>
              entry.dayCareDepartmentDirector !== "" &&
              entry.dayCareDepartmentDirector !== null,
          )
          .slice(-1)[0]?.dayCareDepartmentDirector,
        legalForm: this.activeRegister?.registerEntries
          .filter((entry) => entry.legalForm !== "" && entry.legalForm !== null)
          .slice(-1)[0]?.legalForm,
        comment: this.activeRegister.registerEntries
          .filter((entry) => entry.comment !== "" && entry.comment !== null)
          .slice(-1)[0]?.comment,
        lastStatuteChangeDate: this.activeRegister?.registerEntries
          .filter(
            (entry) =>
              entry.lastStatuteChangeDate !== "" &&
              entry.lastStatuteChangeDate !== null,
          )
          .slice(-1)[0]?.lastStatuteChangeDate,
        createdAt: this.activeRegister?.registerEntries.slice(-1)[0]?.createdAt,
        createdBy: this.activeRegister?.registerEntries
          .filter((entry) => entry.createdBy !== "" && entry.createdBy !== null)
          .slice(-1)[0]?.createdBy,
      };
    },
    layout() {
      return this.$route.query.layout == "iframe-layout"
        ? "IFrameLayout"
        : "MainLayout";
    },
  },
  methods: {
    async selectRegister(registerId) {
      const response = await this.$apollo.query({
        query: gql`
          query Register($registerId: ID!) {
            register(id: $registerId) {
              title
              id
              address
              creationDate
              registerDocuments {
                id
                documentUrl
                documentName
              }
              registerEntries {
                id
                board
                chairman
                deputyChairman
                managementDirector
                socialDepartmentDirector
                centralServicesDirector
                educationDepartmentDirector
                youthDepartmentDirector
                hospiceDepartmentDirector
                dayCareDepartmentDirector
                legalForm
                regulations
                registerId
                comment
                lastStatuteChangeDate
                createdAt
                createdBy {
                  id
                  firstName
                  lastName
                }
              }
            }
          }
        `,
        variables: {
          registerId,
        },
      });
      this.activeRegister = {
        ...response.data.register,
        registerEntries: response.data.register.registerEntries.sort((a, b) =>
          compareAsc(new Date(a.createdAt), new Date(b.createdAt)),
        ),
      };
    },
  },
};
</script>
<style lang="stylus">
.table-entry
  display: block

.register-table
  font-size: 10px
  width: 100%
  border-collapse: collapse
  border-spacing: 0

.register-td
  border: 1px solid grey
  padding: 10px

.register-th
  border: 1px solid grey
  text-align: left
  padding: 10px
.span-group
  & > span:not(:last-child)::after
    content ','
    display inline-block
    margin-right 2px
</style>
