<template lang="pug">
div
  v-btn(@click="registerEntryCreate(register.id)") Register Eintrag Anlegen
  v-text-field(v-model="search" label="Eintrag suchen")
  v-spacer
  v-dialog(v-model='isDialogActive' hide-overlay transition="dialog-bottom-transition" scrollable)
    v-card(tile)
      v-toolbar(card dark color='#009dd1') {{ dialogTitle }}
      v-card-text
        v-text-field(label="Vorstandsvorsitzende*r" v-model="registerEntry.chairman")
        v-text-field(label="Vorstand" v-model="registerEntry.board")
        v-text-field(label="stellvertretende*r Vorsitzende*r" v-model="registerEntry.deputyChairman")
        v-text-field(label="Geschäftsführung" v-model="registerEntry.managementDirector")
        v-text-field(label="Vertretung Abteilung Zentrale Dienste" v-model="registerEntry.centralServicesDirector")
        v-text-field(label="Vertretung Abteilung Soziales" v-model="registerEntry.socialDepartmentDirector")
        v-text-field(label="Vertretung Abteilung Bildung/Hum. Lebenskunde" v-model="registerEntry.educationDepartmentDirector")
        v-text-field(label="Vertretung Abteilung Hum. Kindertagesstätten" v-model="registerEntry.dayCareDepartmentDirector")
        v-text-field(label="Vertretung Abteilung Jugend" v-model="registerEntry.youthDepartmentDirector")
        v-text-field(label="Vertretung Abteilung Hum. Hospize" v-model="registerEntry.hospiceDepartmentDirector")
        v-text-field(label="Angaben zur Rechtsform" v-model="registerEntry.legalForm")
        v-text-field(label="Allgemeine Vertretungsregelungen" v-model="registerEntry.regulations")
        v-text-field(label="Kommentar" v-model="registerEntry.comment")
        input-date(label="Datum der letzten Satzungsänderung" v-model="registerEntry.lastStatuteChangeDate")
        template(v-if="dialogType === 'create-register-entry'")
          v-btn(@click="confirmRegisterEntryCreate") Speichern
          v-btn(@click.native="closeDialog") Abbrechen
        template(v-else)
          v-btn(@click="confirmRegisterEntryUpdate") Speichern
          v-btn(@click.native="closeDialog") Abbrechen
  v-data-table(
    no-data-text="Keine Daten vorhanden" 
    no-results-text="keine passenden Daten"
    :headers="registerEntryHeaders"
    :items="register.registerEntries"
    hide-actions
    :search="search"
  )
    template(slot="items" slot-scope="props")
      tr
        td {{ props.item.id }}
        td {{ props.item.chairman }}
        td {{ props.item.board }}
        td {{ props.item.deputyChairman }}
        td {{ props.item.managementDirector }}
        td {{ props.item.socialDepartmentDirector }}
        td {{ props.item.centralServicesDirector }}
        td {{ props.item.educationDepartmentDirector }}
        td {{ props.item.youthDepartmentDirector }}
        td {{ props.item.hospiceDepartmentDirector }}
        td {{ props.item.dayCareDepartmentDirector }}
        td {{ props.item.legalForm }}
        td {{ props.item.regulations }}
        td {{ props.item.comment }}
        td {{ props.item.lastStatuteChangeDate }}
        td {{ props.item.createdBy.firstName }} {{ props.item.createdBy.lastName }}
        td
          v-btn(icon @click="registerEntryUpdate(props.item)"): v-icon edit
        td
          v-btn(icon @click="registerEntryDestroy(props.item.id)"): v-icon delete
</template>
<script>
import gql from "graphql-tag";
import GraphqlErrorObject from "./../graphql-error-object.js";
import InputDate from "../components/input-date.vue";
export default {
  name: "RegisterEntriesTable",
  components: {
    InputDate,
  },
  mixins: [GraphqlErrorObject],
  props: {
    registerId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      search: "",
      isDialogActive: false,
      register: {},
      dialogType: "",
      registerEntryHeaders: [
        { text: "ID", value: "id" },
        { text: "Vorsitzende*r", value: "chairman" },
        { text: "Vorstand", value: "board" },
        { text: "Stellvertreter*in", value: "deputyChairman" },
        { text: "Geschäftsführer*in", value: "managementDirector" },
        { text: "Zentrale Dienste", value: "centralServicesDirector" },
        { text: "Soziales", value: "socialDepartmentDirector" },
        {
          text: "Bildung",
          value: "educationDepartmentDirector",
        },
        { text: "Kindertagesstätten", value: "dayCareDepartmentDirector" },
        { text: "Jugend", value: "youthDepartmentDirector" },
        { text: "Hospiz", value: "hospiceDepartmentDirector" },
        { text: "Rechtsform", value: "legalForm" },
        { text: "Regelungen", value: "regulations" },
        { text: "Kommentar", value: "comment" },
        {
          text: "Tag der letzten Satzungsänderung",
          value: "lastStatuteChangeDate",
        },
        { text: "Erstellt von", value: "createdBy.firstName" },
        { text: "Eintrag Bearbeiten" },
        { text: "Eintrag Löschen" },
      ],
      registerEntry: {
        registerId: null,
        board: "",
        chariman: "",
        deputyChairman: "",
        managementDirector: "",
        socialDepartmentDirector: "",
        centralServicesDirector: "",
        educationDepartmentDirector: "",
        youthDepartmentDirector: "",
        hospiceDepartmentDirector: "",
        dayCareDepartmentDirector: "",
        legalForm: "",
        regulations: "",
        comment: "",
        lastStatuteChangeDate: "",
      },
    };
  },
  apollo: {
    register: {
      query: gql`
        query Register($registerId: ID!) {
          register(id: $registerId) {
            id
            registerEntries {
              id
              board
              chairman
              deputyChairman
              managementDirector
              socialDepartmentDirector
              centralServicesDirector
              educationDepartmentDirector
              youthDepartmentDirector
              hospiceDepartmentDirector
              dayCareDepartmentDirector
              legalForm
              regulations
              registerId
              comment
              lastStatuteChangeDate
              createdBy {
                id
                firstName
                lastName
              }
            }
          }
        }
      `,
      variables() {
        return {
          registerId: this.registerId,
        };
      },
    },
  },
  computed: {
    dialogTitle() {
      if (this.dialogType === "create-register") {
        return "Neues Register";
      } else if (this.dialogType === "update-register") {
        return "Register bearbeiten";
      }
      return "Neuer Registereintrag";
    },
  },
  methods: {
    registerEntryCreate(registerId) {
      this.gqlClearAllErrors();
      this.dialogType = "create-register-entry";
      this.isDialogActive = true;
      this.registerEntry = {
        id: null,
        registerId,
        board: "",
        chairman: "",
        deputyChairman: "",
        managementDirector: "",
        socialDepartmentDirector: "",
        centralServicesDirector: "",
        educationDepartmentDirector: "",
        youthDepartmentDirector: "",
        hospiceDepartmentDirector: "",
        dayCareDepartmentDirector: "",
        legalForm: "",
        regulations: "",
        comment: "",
        lastStatuteChangeDate: "",
      };
    },
    async confirmRegisterEntryCreate() {
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation RegisterEntryCreate(
              $board: String
              $registerId: ID!
              $chairman: String
              $deputyChairman: String
              $managementDirector: String
              $socialDepartmentDirector: String
              $centralServicesDirector: String
              $educationDepartmentDirector: String
              $youthDepartmentDirector: String
              $hospiceDepartmentDirector: String
              $dayCareDepartmentDirector: String
              $legalForm: String
              $regulations: String
              $comment: String
              $lastStatuteChangeDate: String
            ) {
              registerEntryCreate(
                registerId: $registerId
                board: $board
                chairman: $chairman
                deputyChairman: $deputyChairman
                managementDirector: $managementDirector
                socialDepartmentDirector: $socialDepartmentDirector
                centralServicesDirector: $centralServicesDirector
                educationDepartmentDirector: $educationDepartmentDirector
                youthDepartmentDirector: $youthDepartmentDirector
                hospiceDepartmentDirector: $hospiceDepartmentDirector
                dayCareDepartmentDirector: $dayCareDepartmentDirector
                legalForm: $legalForm
                regulations: $regulations
                comment: $comment
                lastStatuteChangeDate: $lastStatuteChangeDate
              ) {
                id
              }
            }
          `,
          variables: {
            board: this.registerEntry.board,
            registerId: this.registerEntry.registerId,
            chairman: this.registerEntry.chairman,
            deputyChairman: this.registerEntry.deputyChairman,
            managementDirector: this.registerEntry.managementDirector,
            socialDepartmentDirector:
              this.registerEntry.socialDepartmentDirector,
            centralServicesDirector: this.registerEntry.centralServicesDirector,
            educationDepartmentDirector:
              this.registerEntry.educationDepartmentDirector,
            youthDepartmentDirector: this.registerEntry.youthDepartmentDirector,
            hospiceDepartmentDirector:
              this.registerEntry.hospiceDepartmentDirector,
            dayCareDepartmentDirector:
              this.registerEntry.dayCareDepartmentDirector,
            legalForm: this.registerEntry.legalForm,
            regulations: this.registerEntry.regulations,
            comment: this.registerEntry.comment,
            lastStatuteChangeDate: this.registerEntry.lastStatuteChangeDate,
          },
        });
        await this.$apollo.queries.register.refetch();
        this.isDialogActive = false;
      } catch (error) {
        this.gqlAddErrors(error);
      }
    },
    registerEntryUpdate(registerEntry) {
      this.gqlClearAllErrors();
      this.dialogType = "update-register-entry";
      this.isDialogActive = true;
      this.registerEntry = registerEntry;
    },
    async confirmRegisterEntryUpdate() {
      this.gqlClearAllErrors();
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation RegisterEntryUpdate(
              $id: ID!
              $board: String
              $chairman: String
              $deputyChairman: String
              $managementDirector: String
              $socialDepartmentDirector: String
              $centralServicesDirector: String
              $educationDepartmentDirector: String
              $youthDepartmentDirector: String
              $hospiceDepartmentDirector: String
              $dayCareDepartmentDirector: String
              $legalForm: String
              $regulations: String
              $comment: String
              $lastStatuteChangeDate: String
            ) {
              registerEntryUpdate(
                id: $id
                board: $board
                chairman: $chairman
                deputyChairman: $deputyChairman
                managementDirector: $managementDirector
                socialDepartmentDirector: $socialDepartmentDirector
                centralServicesDirector: $centralServicesDirector
                educationDepartmentDirector: $educationDepartmentDirector
                youthDepartmentDirector: $youthDepartmentDirector
                hospiceDepartmentDirector: $hospiceDepartmentDirector
                dayCareDepartmentDirector: $dayCareDepartmentDirector
                legalForm: $legalForm
                regulations: $regulations
                comment: $comment
                lastStatuteChangeDate: $lastStatuteChangeDate
              ) {
                id
              }
            }
          `,
          variables: {
            id: this.registerEntry.id,
            board: this.registerEntry.board,
            chairman: this.registerEntry.chairman,
            deputyChairman: this.registerEntry.deputyChairman,
            managementDirector: this.registerEntry.managementDirector,
            socialDepartmentDirector:
              this.registerEntry.socialDepartmentDirector,
            centralServicesDirector: this.registerEntry.centralServicesDirector,
            educationDepartmentDirector:
              this.registerEntry.educationDepartmentDirector,
            youthDepartmentDirector: this.registerEntry.youthDepartmentDirector,
            hospiceDepartmentDirector:
              this.registerEntry.hospiceDepartmentDirector,
            dayCareDepartmentDirector:
              this.registerEntry.dayCareDepartmentDirector,
            legalForm: this.registerEntry.legalForm,
            regulations: this.registerEntry.regulations,
            comment: this.registerEntry.comment,
            lastStatuteChangeDate: this.registerEntry.lastStatuteChangeDate,
          },
        });
        this.isDialogActive = false;
        await this.$apollo.queries.register.refetch();
      } catch (error) {
        this.gqlAddErrors(error);
      }
    },
    async registerEntryDestroy(id) {
      if (!confirm("Registereintrag wirklich löschen?")) {
        return;
      }
      this.gqlClearAllErrors();
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation RegisterEntryDestroy($id: ID!) {
              registerEntryDestroy(id: $id) {
                id
              }
            }
          `,
          variables: {
            id,
          },
        });
        await this.$apollo.queries.register.refetch();
      } catch (error) {
        this.gqlAddErrors(error);
      }
    },
    closeDialog() {
      this.isDialogActive = false;
      this.gqlClearAllErrors();
    },
  },
};
</script>
