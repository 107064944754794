<template lang="pug">
v-container
  v-layout(justify-center)
    v-card(width="900")
      v-card-text
        template(v-if="edit")
          v-btn(@click="save" color="primary") Speichern
          v-btn(@click="cancelEdit") Abbrechen
        template(v-else)
          template(v-if="$store.getters.isAdmin")
            v-btn(@click="startEdit") Bearbeiten

          div(v-html="gesundheitstagFaq")

</template>

<script>
import gql from "graphql-tag";
import GraphqlErrorObject from "./graphql-error-object.js";

export default {
  mixins: [GraphqlErrorObject],
  apollo: {
    gesundheitstagFaq: {
      query: gql`
        query FaqVue {
          gesundheitstagFaq
        }
      `,
    },
  },
  data() {
    return {
      edit: null,
      gesundheitstagFaq: "",
    };
  },
  methods: {
    startEdit() {
      this.edit = { html: this.gesundheitstagFaq };
    },
    cancelEdit() {
      this.edit = null;
      this.gqlClearAllErrors();
    },
    save() {
      this.gqlClearAllErrors();
      this.$apollo
        .mutate({
          mutation: gql`
            mutation FaqVueUpdate($html: String) {
              gesundheitstagFaqUpdate(html: $html)
            }
          `,
          variables: this.edit,
        })
        .then((data) => {
          this.$apollo.queries.gesundheitstagFaq.refetch();
          this.edit = false;
        })
        .catch((error) => {
          this.gqlAddErrors(error);
        });
    },
  },
};
</script>
<style lang="stylus" scoped>
.error-msg
  padding 2em 0
  text-align center
</style>

