export default {
  data() {
    return {
      graphqlErrorsObject: {},
    };
  },
  methods: {
    gqlAddErrors(error, keepOldErrors = false) {
      if (!error.graphQLErrors) {
        return error.netWorkErrors || [];
      }

      if (!keepOldErrors) {
        error.graphQLErrors.forEach((err) => {
          let root = err.path && err.path[0];
          if (root && this.graphqlErrorsObject[root]) {
            this.graphqlErrorsObject[root] = null;
          }
        });
      }

      let globalErrors = [];
      error.graphQLErrors.forEach((err) => {
        let node = this.graphqlErrorsObject;
        if (err.path && err.path.length) {
          err.path.forEach((path) => {
            if (!node[path]) {
              this.$set(node, path, { eRrOrs: [] });
            }
            node = node[path];
          });
          node.eRrOrs.push(err.message);
        } else {
          globalErrors.push(err);
        }
      });

      return globalErrors;
    },
    gqlClearErrorsFor(...roots) {
      roots.forEach((root) => {
        if (this.graphqlErrorsObject[root]) {
          this.graphqlErrorsObject[root] = null;
        }
      });
    },
    gqlClearAllErrors() {
      Object.keys(this.graphqlErrorsObject).forEach((root) => {
        if (this.graphqlErrorsObject[root]) {
          this.graphqlErrorsObject[root] = null;
        }
      });
    },
    gqlErrorsFor(...path) {
      let node = this.graphqlErrorsObject;
      path.every((elem) => {
        node = node[elem];
        return node;
      });
      return (node && node.eRrOrs) || [];
    },
  },
};
