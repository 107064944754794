import VueRouter from "vue-router";
import FAQ from "./FAQ";
import UserLogin from "./users/UserLogin";
import store from "./store";
import Index from "./Index";
import RegisterEdit from "./register/RegisterEdit.vue";
import RegisterShow from "./register/RegisterShow.vue";
import UsersEdit from "./users/UsersEdit.vue";

let routes = [];

let site;

const isAuthenticated = (_to, _from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/login");
};


routes = routes.concat([
  { path: "/", component: Index },
  { path: "/register", component: RegisterShow },
  { path: "/login", component: UserLogin },
  { path: "/users/edit", component: UsersEdit, beforeEnter: isAuthenticated },
  { path: "/faq", component: FAQ },
  {
    path: "/register/edit",
    component: RegisterEdit,
    beforeEnter: isAuthenticated,
  },
]);

const router = new VueRouter({
  base: "/",
  mode: "history",
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export { site };
export default router;
