<template lang="pug">
main-layout
  v-dialog(v-model='isDialogActive' hide-overlay transition="dialog-bottom-transition" scrollable)
    v-card(tile)
      v-toolbar(card dark color='#009dd1') {{ dialogTitle }}
      v-card-text
        v-text-field(label="Vorname" v-model="user.firstName")
        v-text-field(label="Nachname" v-model="user.lastName")
        v-text-field(label="Benutzername" v-model="user.username")
        v-text-field(label="Email" v-model="user.email")
        .info-text Wenn du das Passwort nicht ändern möchtest, gebe einfach das aktuelle Passwort ein.
        v-text-field(label="Passwort" v-model="user.password" type="password")
        v-text-field(label="Passwort Bestätigung" v-model="user.passwordConfirmation" type="password")
        div(v-if="dialogType === 'create-user'")
          v-card-actions
            v-btn(@click="confirmUserCreate") Speichern
            v-btn(@click.native="closeDialog") Abbrechen
        div(v-else)
          v-card-actions
            v-btn(@click="confirmUserUpdate") Speichern
            v-btn(@click.native="closeDialog") Abbrechen
        div.error-msg(v-for="error in validationErrors" :key="error") {{ error.message }}

  v-data-table(
    must-sort 
    no-data-text="Keine Daten vorhanden" 
    no-results-text="keine passenden Daten"
    hide-actions
    item-key="id"
    :headers="userTableHeaders"
    :items="allUsers"
  )
    template(slot="items" slot-scope="props")
      tr
        td {{ props.item.id }}
        td {{ props.item.firstName }}
        td {{ props.item.lastName }}
        td {{ props.item.username }}
        td {{ props.item.email }}
        td
          v-btn(icon @click="userUpdate(props.item)"): v-icon edit
        td
          v-btn(icon @click="userDestroy(props.item.id)"): v-icon delete
  v-btn(@click="userCreate") Neuen Nutzer anlegen
</template>

<script>
import gql from "graphql-tag";
import GraphqlErrorObject from "./../graphql-error-object.js";
import MainLayout from "../layouts/Main.vue";
import { z } from "zod";

export default {
  components: {
    MainLayout,
  },
  mixins: [GraphqlErrorObject],
  data() {
    return {
      isDialogActive: false,
      dialogType: "",
      user: {
        id: "",
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        password: "",
        passwordConfirmation: "",
      },
      validationErrors: [],
      userTableHeaders: [
        { text: "ID", value: "id" },
        { text: "Vorname", value: "firstName" },
        { text: "Nachname", value: "lastName" },
        { text: "Benutzername", value: "username" },
        { text: "Email", value: "email" },
        { text: "Nutzer bearbeiten", value: "edit" },
        { text: "Nutzer löschen", value: "delete" },
      ],
    };
  },
  apollo: {
    allUsers: gql`
      query {
        allUsers {
          id
          firstName
          lastName
          username
          email
        }
      }
    `,
  },
  computed: {
    dialogTitle() {
      if (this.dialogType === "create-user") {
        return "Neuen Nutzer anlegen";
      }
      return "Nutzer bearbeiten";
    },
  },
  methods: {
    userCreate() {
      this.gqlClearAllErrors();
      this.isDialogActive = true;
      this.dialogType = "create-user";
      this.user = {
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        password: "",
        passwordConfirmation: "",
      };
    },
    async confirmUserCreate() {
      try {
        if (!this.validate()) {
          return;
        }
        await this.$apollo.mutate({
          mutation: gql`
            mutation UserCreate(
              $firstName: String!
              $lastName: String!
              $username: String!
              $email: String!
              $password: String!
              $passwordConfirmation: String!
            ) {
              userCreate(
                firstName: $firstName
                lastName: $lastName
                username: $username
                email: $email
                password: $password
                passwordConfirmation: $passwordConfirmation
              ) {
                id
              }
            }
          `,
          variables: {
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            username: this.user.username,
            email: this.user.email,
            password: this.user.password,
            passwordConfirmation: this.user.passwordConfirmation,
          },
        });
        this.isDialogActive = false;
        await this.$apollo.queries.allUsers.refetch();
      } catch (error) {
        this.gqlAddErrors(error);
      }
    },

    userUpdate(user) {
      this.gqlClearAllErrors();
      this.dialogType = "update-user";
      this.isDialogActive = true;
      this.user = {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.username,
        email: user.email,
        password: "",
        passwordConfirmation: "",
      };
    },
    async confirmUserUpdate() {
      try {
        if (!this.validate()) {
          return;
        }
        await this.$apollo.mutate({
          mutation: gql`
            mutation (
              $id: ID!
              $firstName: String!
              $lastName: String!
              $username: String!
              $email: String!
              $password: String!
              $passwordConfirmation: String!
            ) {
              userUpdate(
                id: $id
                firstName: $firstName
                lastName: $lastName
                username: $username
                email: $email
                password: $password
                passwordConfirmation: $passwordConfirmation
              ) {
                id
              }
            }
          `,
          variables: {
            id: this.user.id,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            username: this.user.username,
            email: this.user.email,
            password: this.user.password,
            passwordConfirmation: this.user.passwordConfirmation,
          },
        });
        this.isDialogActive = false;
        await this.$apollo.queries.allUsers.refetch();
      } catch (error) {
        this.gqlAddErrors(error);
      }
    },

    async userDestroy(id) {
      if (!confirm("Nutzer wirklich löschen?")) {
        return;
      }
      this.gqlClearAllErrors();
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation UserDestroy($id: ID!) {
              userDestroy(id: $id) {
                id
              }
            }
          `,
          variables: {
            id: id,
          },
        });
        await this.$apollo.queries.allUsers.refetch();
      } catch (error) {
        this.gqlAddErrors(error);
      }
    },
    closeDialog() {
      this.isDialogActive = false;
      this.gqlClearAllErrors();
    },
    validate() {
      this.validationErrors = []
      const schema = z.object({
        firstName: z.string().min(1, { message: "Vorname ist ein Pflichtfeld" }),
        lastName: z.string().min(1, { message: "Nachname ist ein Pflichtfeld" }),
        username: z.string().min(1, { message: "Benutzername ist ein Pflichtfeld" }),
        email: z.string().email({ message: "Dies ist keine richtige Emailadresse" }),
        password: z.string().min(8, { message: "Passwort muss mindestens 8 Zeichen lang sein" }),
        passwordConfirmation: z.string().min(8, { message: "Passwort Bestätigung muss mindestens 8 Zeichen lang sein" }),
      }).refine((data) => data.password === data.passwordConfirmation, {
        message: "Passwort und Passwort Bestätigung stimmen nicht überein",
        path: ["passwordConfirmation"]
      })
      const result = schema.safeParse(this.user)
      if (!result.success) {
        this.validationErrors = result.error.issues
        return false
      }
      return true
    }
  },
};
</script>
<style lang="stylus" scoped>
.error-msg
  color: red
.info-text
  color: #009dd1
  font-weight: bold
  font-size: 1.5em
</style>
