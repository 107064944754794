/* eslint no-console: 0 */
import 'whatwg-fetch'
import Vue from 'vue'
import VueRouter from 'vue-router'
import router from '../src/router'
import App from '../src/app.vue'
import Vuetify from 'vuetify'
import '../styles/app.stylus'
import de from '../src/locale/de'
import store from '../src/store'

import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { ApolloLink } from 'apollo-link'
import { InMemoryCache } from 'apollo-cache-inmemory'
import VueApollo from 'vue-apollo'
import '../vendor/iframeResizer.contentWindow.min.js'
import AsyncComputed from 'vue-async-computed'
import './application.css'

Vue.use(AsyncComputed)
Vue.use(VueApollo)
Vue.use(VueRouter)
Vue.use(Vuetify, {
  theme: {
    primary: '#0053a6',
    secondary: '#009dd1',
  },
  lang: {
    locales: { de },
    current: 'de'
  }
})

const httpLink = new HttpLink({ uri: '/graphql' })
const middlewareLink = new ApolloLink((operation, forward) => {
  // This function is called before every request.
  const token = JSON.parse(localStorage.getItem('auth-token') || null)
  if (token) {
    operation.setContext({
      headers: {
        'Authorization': `Token ${token}`
      }
    })
  }
  return forward(operation)
})
const operationAliasLink = function(operation, forward) {
  if (operation.operationName && /^GQLS_/.test(operation.operationName)) {
      operation.setContext({
        http: {
          includeQuery: false,
        }
      })
  }
  return forward(operation)
}

const link = ApolloLink.from([
  operationAliasLink,
  middlewareLink,
  httpLink
])

const apolloClient = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
  connectToDevTools: true,
})

const apolloProvider = new VueApollo({ defaultClient: apolloClient })

const app = new Vue({
  el: '#app',
  router,
  store,
  apolloProvider,
  render: h => h(App)
})
