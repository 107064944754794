<template lang="pug">
  v-app
    v-content
      router-view(style="width: 100%")

</template>

<script>
import gql from "graphql-tag";
import { site } from "./router.js";

export default {
  data() {
    return {
      site,
      drawer: true,
    };
  },
  apollo: {
    currentUser: {
      query: gql`
        query AppVueCurrentUser {
          currentUser {
            id
            firstName
            lastName
            email
          }
        }
      `,
      skip() {
        return !this.$store.getters.isAuthenticated;
      },
    },
  },
  methods: {
    logout() {
      this.$store.commit("logout");
      this.$router.push("/");
      this.$apollo.provider.defaultClient.resetStore();
    },
    inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
  },
};
</script>
