<template lang="pug">
div
  v-img(v-if="url" :src="url" :contain="contain" :max-height="maxHeight" :max-width="maxWidth" :position="position")
  label
    input.hide-fileinput(
      tabindex="-1"
      type="file"
      :accept="accept"
      :multiple="multiple"
      :disabled="disabled"
      ref="fileInput"
      @change="onFileChange"
    )
    template(v-if="ios")
      v-btn(style="display: inline-block") {{label}}
    template(v-else)
      v-btn(style="display: inline-block" @click="$refs.fileInput.click()") {{label}}  
  v-btn(v-if="preview && url" icon @click="showPreview = true"): v-icon fullscreen
  v-btn(v-if="clearable && value" icon @click="clear"): v-icon clear
  span(v-if="!hideName") {{value || ''}}
  v-dialog(v-if="preview && url" v-model="showPreview" fullscreen)
    v-card.grey.lighten-4
      v-card-title.primary.white--text
        | {{value || ''}}
        v-spacer
        v-btn(icon @click="showPreview = false" dark): v-icon clear
      v-card-text.text-xs-center.file-img-container
        img(:src="urlBig || url")
  v-messages(v-if="errorMessages" :value="errorMessages" color="error")

</template>

<style lang="stylus" scoped>
.hide-fileinput
  position absolute
  left -99999px
.file-img-container
  overflow-x auto
</style>

<script>
export default {
  props: {
    value: {
      type: [Array, String],
    },
    accept: {
      type: String,
      default: "*",
    },
    label: {
      type: String,
      default: "Datei Auswählen",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    base64: {
      type: Boolean,
    },
    errorMessages: {
      type: Array,
    },
    clearable: {
      type: Boolean,
    },
    hideName: {
      type: Boolean,
    },
    preview: {
      type: Boolean,
    },
    url: {
      type: String,
    },
    urlBig: {
      type: String,
    },
    maxHeight: {
      type: Number | String,
      default: 100,
    },
    maxWidth: {
      type: Number | String,
      default: 100,
    },
    contain: {
      type: Boolean,
      default: true,
    },
    position: {
      type: String,
      default: "center center",
    },
  },
  data() {
    return {
      filename: "",
      showPreview: false,
    };
  },
  watch: {
    value(v) {
      this.showPreview = false;
      this.filename = v;
    },
  },
  mounted() {
    this.filename = this.value;
  },
  computed: {
    ios() {
      return (
        !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
      );
    },
  },
  methods: {
    getFormData(files) {
      const forms = [];
      for (const file of files) {
        const form = new FormData();
        form.append("data", file, file.name);
        forms.push(form);
      }
      return forms;
    },
    onFocus() {
      if (!this.disabled) {
        this.$refs.fileInput.click();
      }
    },
    clear() {
      this.$emit("input", null);
      if (this.base64) {
        this.$emit("base64", null);
      } else {
        this.$emit("formData", null);
        this.$emit("files", null);
      }
    },
    async onFileChange($event) {
      const files = $event.target.files || $event.dataTransfer.files;
      const form = this.getFormData(files);
      if (files) {
        if (files.length > 0) {
          this.filename = [...files].map((file) => file.name).join(", ");
        } else {
          this.filename = null;
        }
      } else {
        this.filename = $event.target.value.split("\\").pop();
      }
      if (this.base64) {
        if (files[0]) {
          const data = await this.toBase64(files[0]);
          this.$emit("input", this.filename);
          this.$emit("base64", data);
        }
      } else {
        this.$emit("input", this.filename);
        this.$emit("formData", form);
        this.$emit("files", files);
      }
    },
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          function () {
            resolve(this.result);
          },
          false,
        );

        reader.readAsDataURL(file);
      });
    },
  },
};
</script>
