<template lang="pug">
  div
    v-toolbar(app flat)
      v-toolbar-items
        template(v-if="$store.getters.isAuthenticated")
          v-list-tile
          v-list-tile(to="/register") Register
          v-list-tile(to="/register/edit") Register bearbeiten
          v-list-tile(to="/users/edit") Benutzer bearbeiten
          v-list-tile(@click="logout") Ausloggen
        template(v-else)
          v-list-tile(to="/login") Anmelden
          v-list-tile(to="/register") Register
    slot
    v-footer
      v-spacer
      div.footer
        a(href="https://humanistisch.de/x/hvd-bb/inhalte/datenschutz") Datenschutzerklärung
        |  &nbsp;
        a(href="https://humanistisch.de/x/hvd-bb/inhalte/impressum") Impressum
        |  &nbsp;
        router-link(to="/faq") FAQ
</template>
<script>
import gql from 'graphql-tag'

export default {
  data() {
    return {
    }
  },
  apollo: {
    currentUser: {
      query: gql`query AppVueCurrentUser {
        currentUser { id firstName lastName email }
      }`,
      skip() {
        return !this.$store.getters.isAuthenticated
      }
    }
  },
  methods: {
    logout() {
      this.$store.commit('logout')
      this.$router.push('/')
      this.$apollo.provider.defaultClient.resetStore()
    },
  }
}
</script>
