import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    currentUser: JSON.parse(localStorage.getItem("auth-user") || null),
    authToken: JSON.parse(localStorage.getItem("auth-token") || null),
  },
  getters: {
    isAuthenticated(state) {
      return !!state.authToken;
    },
    currentUserId(state) {
      return state.currentUser && state.currentUser.id;
    },
  },
  mutations: {
    storeAuth(state, userSignIn) {
      localStorage.setItem(
        "auth-user",
        JSON.stringify(userSignIn.user || null),
      );
      localStorage.setItem(
        "auth-token",
        JSON.stringify(userSignIn.token || null),
      );
      state.currentUser = JSON.parse(localStorage.getItem("auth-user") || null);
      state.authToken = JSON.parse(localStorage.getItem("auth-token") || null);
    },
    logout(state) {
      localStorage.removeItem("auth-user");
      localStorage.removeItem("auth-token");
      state.authToken = null;
    },
  },
});

export default store;
